import React, { useEffect, useRef, useState } from "react";
import ImageFlipImage, { imageFlipImageProps } from "./imageFlipImage";
//
// import { TIME_DELTA } from "const/const";

interface imageFlipProps {
  images: any[];
}

const ImageFlip: React.FC<imageFlipProps> = (props) => {
  const aFrame = useRef<any>(0);
  const tCurrent = useRef<number>(0);
  const tDelta = useRef<number>(0);
  const postLast = useRef<{ x: number; y: number }>({ x: 0, y: 0 });
  const index = useRef<number>(0);
  //
  const [renderAr, setRenderAr] = useState<imageFlipImageProps[]>([]);
  const renderArRef = useRef<imageFlipImageProps[]>([]);

  useEffect(() => {
    const render = () => {
      const now = window.performance.now();
      const diff = now - tCurrent.current;
      tCurrent.current = now;
      tDelta.current = tDelta.current + diff;
      //
      aFrame.current = requestAnimationFrame(render);
    };
    tCurrent.current = window.performance.now();
    aFrame.current = requestAnimationFrame(render);
    return () => cancelAnimationFrame(aFrame.current);
  }, []);

  useEffect(() => {
    const doPushNewImage = (x: number, y: number) => {
      index.current = index.current + 1;

      const _renderAr = [...renderArRef.current];
      const _image = props.images[index.current % props.images.length].image;
      _renderAr.push({
        url: _image.mediaItemUrl,
        x,
        y,
        index: index.current,
        size: _image.mediaDetails,
      });

      renderArRef.current = _renderAr;
      setRenderAr(_renderAr);
    };

    const handleMouseMove = (e: MouseEvent) => {
      const a = e.clientX - postLast.current.x;
      const b = e.clientY - postLast.current.y;
      const d = Math.sqrt(a * a + b * b);

      if (Math.abs(d) > window.innerWidth / 10) {
        postLast.current = { x: e.clientX, y: e.clientY };
        doPushNewImage(e.clientX, e.clientY);
      }
    };

    const handleTouchMove = (e: TouchEvent) => {
      const touch = e.touches[0];
      const a = touch.screenX - postLast.current.x;
      const b = touch.screenY - postLast.current.y;
      const d = Math.sqrt(a * a + b * b);

      if (Math.abs(d) > window.innerWidth / 10) {
        postLast.current = { x: touch.screenX, y: touch.screenY };
        doPushNewImage(touch.screenX, touch.screenY);
      }
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("touchmove", handleTouchMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("touchmove", handleTouchMove);
    };
  }, [renderAr]);

  const doDestroyImage = () => {
    const _renderAr = [...renderArRef.current];
    _renderAr.shift();
    renderArRef.current = _renderAr;
    setRenderAr(_renderAr);
  };

  return (
    <div className="imageFlip">
      {renderAr.map((item: imageFlipImageProps, key: number) => (
        <ImageFlipImage
          {...item}
          key={`img${item.index}`}
          onDestroy={doDestroyImage}
        />
      ))}
    </div>
  );
};

export default ImageFlip;
